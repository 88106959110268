import { Space, Tag, Typography } from 'antd';
import Table from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { BLACK } from '../../../constants/color';
import {
  PRINTOUT_DATA_FONT_SIZE,
  PRINTOUT_FOCUS_FONT_SIZE,
} from '../../../constants/printoutConstants';
import { LARGE_FONT_SIZE } from '../../../constants/systemConstants';
import { PrintoutOrderTableData, PrintoutTable } from '../../../types';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type OrderPrintoutTableProps = {
  printoutTableData: PrintoutOrderTableData;
  isFullVersion?: boolean;
  showPrice?: boolean;
};

const OrderPrintoutTable = ({
  printoutTableData,
  isFullVersion,
  showPrice = true,
}: OrderPrintoutTableProps) => {
  //General Components
  const { t } = useTranslation();
  const mergeRowCelCondition = (
    record: PrintoutTable,
    renderHtml: any,
    isExpand: boolean = false,
    columnIndex: number
  ) => {
    const obj: any = {
      children: (
        <div
          style={{
            fontSize: PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
            textAlign: 'center',
          }}
        >
          {t('order.print.divider')}
        </div>
      ),
      props: {},
    };
    const objWithColSpan: any = {
      children: renderHtml,
      props: {},
    };
    if (record.goodsId === undefined) {
      obj.props.colSpan = isExpand ? 9 : 0;
      return obj;
    } else {
      if (isNaN(record.goodsId)) {
        objWithColSpan.props.colSpan =
          columnIndex > 2 ? 0 : columnIndex === 2 ? 7 : undefined;
        return objWithColSpan;
      } else {
        return renderHtml;
      }
    }
  };

  const columns = [
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.cat')}
          </Typography.Text>
        </Space>
      ),
      key: 'cat',
      dataIndex: 'cat',
      width: 50,
      render: (text: any, record: PrintoutTable, index: number) =>
        mergeRowCelCondition(
          record,
          <div
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: PRINTOUT_DATA_FONT_SIZE,
            }}
          >
            {text}
          </div>,
          true,
          0
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsId')}
          </Typography.Text>
        </Space>
      ),
      key: 'goodsId',
      dataIndex: 'goodsId',
      width: 80,
      render: (text: any, record: PrintoutTable, index: number) =>
        mergeRowCelCondition(
          record,
          <div
            style={{ textAlign: 'center', fontSize: PRINTOUT_DATA_FONT_SIZE }}
          >
            {text}
          </div>,
          false,
          1
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsName')}
          </Typography.Text>
        </Space>
      ),
      key: 'goodsName',
      dataIndex: 'goodsName',
      render: (text: string, record: PrintoutTable, index: number) =>
        mergeRowCelCondition(
          record,
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              flexDirection: 'column',
            }}
          >
            <div>
              {record.giftSpecial && (
                <Typography.Text
                  style={{
                    fontSize:
                      PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
                    color: record.giftSpecial
                      ? record.giftSpecial.tagColor
                      : undefined,
                    wordBreak: 'break-word',
                    fontWeight: 'bold',
                  }}
                >
                  {`${
                    record.giftSpecial
                      ? `【${record.giftSpecial.description}】`
                      : ''
                  }`}
                </Typography.Text>
              )}
              <Typography.Text
                style={{
                  fontSize: PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
                  wordBreak: 'break-word',
                }}
              >
                {`${record.goodsName ? record.goodsName : ''}`}
              </Typography.Text>
            </div>
            {record.goods && record.goods.barcode && (
              <div>
                <Typography.Text
                  style={{
                    fontSize:
                      PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
                    wordBreak: 'break-word',
                  }}
                >
                  {`${
                    record.goods
                      ? `${t('order.printoutColumns.barcode')}: ${
                          record.goods.barcode
                        }`
                      : ''
                  }`}
                </Typography.Text>
              </div>
            )}
          </div>,
          false,
          2
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            wordBreak: 'break-word',
          }}
        >
          <Typography.Text style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsUnit')}
          </Typography.Text>
        </Space>
      ),
      key: 'goodsUnit',
      dataIndex: 'goodsUnit',
      width: 120,
      render: (text: number, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            size={0}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Typography.Text
              style={{
                fontSize: PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
              }}
            >
              {text}
            </Typography.Text>
          </Space>,
          false,
          3
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsAttrName')}
          </Typography.Text>
        </Space>
      ),
      key: 'goodsAttrName',
      dataIndex: 'goodsAttrName',
      width: 86,
      render: (text: string, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: PRINTOUT_DATA_FONT_SIZE + PRINTOUT_FOCUS_FONT_SIZE,
            }}
          >
            {text}
          </div>,
          false,
          4
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsNumber')}
          </div>
        </Space>
      ),
      key: 'goodsNumber',
      dataIndex: 'goodsNumber',
      width: 40,
      render: (text: number, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            size={0}
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {text && (
              <div
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  fontWeight: text > 1 ? 'bold' : 'normal',
                  fontSize: PRINTOUT_DATA_FONT_SIZE,
                }}
              >
                {`${text}${
                  record.specialGoodsUnit ? record.specialGoodsUnit : ''
                }`}
              </div>
            )}
          </Space>,
          false,
          5
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsPrice')}
          </div>
        </Space>
      ),
      key: 'goodsPrice',
      dataIndex: 'goodsPrice',
      width: 60,
      render: (text: string, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            size={0}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {text && (
              <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
                {getDashboardStyle().isWholeSaleAppType
                  ? text && `$${text}`
                  : text &&
                    `$${text}${
                      (record.gstFee !== '0.00' &&
                        record.gstFee !== '0.0000') ||
                      (record.pstFee !== '0.00' && record.pstFee !== '0.0000')
                        ? '+tax'
                        : ''
                    }`}
              </div>
            )}
          </Space>,
          false,
          6
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.orderGoodsListColumns.feesInfo')}
          </div>
        </Space>
      ),
      key: 'feesInfo',
      dataIndex: 'feesInfo',
      width: 60,
      render: (text: string, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            direction="vertical"
            size={0}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: '100%',
              // flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {record.gstFee &&
              record.gstFee !== '0.00' &&
              record.gstFee !== '0.0000' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: PRINTOUT_DATA_FONT_SIZE,
                  }}
                >
                  <div>{`G:`}</div>
                  <div>{record.gstFee}%</div>
                </div>
              )}
            {record.pstFee &&
              record.pstFee !== '0.00' &&
              record.pstFee !== '0.0000' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: PRINTOUT_DATA_FONT_SIZE,
                  }}
                >
                  <div>{`P:`}</div>
                  <div>{record.pstFee}%</div>
                </div>
              )}
            {record.depositFee && record.depositFee !== '0.00' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontSize: PRINTOUT_DATA_FONT_SIZE,
                }}
              >
                <div>{`DF:`}</div>
                <div>${record.depositFee}</div>
              </div>
            )}
            {record.recycleFee && record.recycleFee !== '0.00' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontSize: PRINTOUT_DATA_FONT_SIZE,
                }}
              >
                <div>{`RF:`}</div>
                <div>${record.recycleFee}</div>
              </div>
            )}
          </Space>,
          false,
          6
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.goodsWeight')}
          </div>
        </Space>
      ),
      key: 'goodsWeight',
      dataIndex: 'goodsWeight',
      width: 60,
      render: (text: string, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            size={0}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {text && (
              <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>{text}</div>
            )}
          </Space>,
          false,
          6
        ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            paddingLeft: 5,
            paddingRight: 5,

            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
            {t('order.printoutColumns.totalPrice')}
          </div>
        </Space>
      ),
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      width: 60,
      render: (text: string, record: PrintoutTable) =>
        mergeRowCelCondition(
          record,
          <Space
            size={0}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>
              {text && `$${text}`}
            </div>
          </Space>,
          false,
          7
        ),
    },
  ];
  const priceABlock = [
    'goodsAmount',
    'depositFee',
    'recycleFee',
    'gstFee',
    'pstFee',
    'discountAmount',
    'shippingFee',
    'tipsFee',
    'packFee',
    'payFee',
    'totalAmount',
  ];
  const priceBBlock = [
    'totalAmount',
    'couponsAmount',
    'bonusAmount',
    'integralAmount',
    'valueCardAmount',
    'surplusAmount',
    'paidAmount',
    'returnAmount',
    'orderAmount',
  ];

  // 订单商品类型
  const printTagList = () => {
    return printoutTableData.goodsTagList.map((item, index) => {
      return (
        <Tag
          style={{
            fontSize: LARGE_FONT_SIZE,
            fontWeight: 'bold',
            paddingTop: 5,
            paddingBottom: 5,
          }}
          key={index}
        >
          {item}
        </Tag>
      );
    });
  };

  // 特殊商品
  const printSpecialItems = () => {
    return (
      (printoutTableData.table.map((obj) => obj.cat).includes('Special') ||
        printoutTableData.table.map((obj) => obj.cat).includes('特殊商品')) && (
        <Table
          className="tablePrintout"
          dataSource={printoutTableData.table}
          rowKey={(data, index) => `${index}`}
          columns={columns.filter(
            (x) =>
              (showPrice || x.key !== 'goodsPrice') &&
              (showPrice || x.key !== 'totalPrice') &&
              (!getDashboardStyle().isWholeSaleAppType ||
                x.key !== 'goodsAttrName') && // 如果是批发不显示这个
              (getDashboardStyle().isWholeSaleAppType || x.key !== 'feesInfo') // 如果不是批发就不显示这个
          )}
          pagination={false}
          size="small"
          bordered={true}
          style={{
            marginBottom: 15,
          }}
        />
      )
    );
  };

  return (
    <>
      {!isFullVersion &&
      getDashboardStyle(
        process.env.REACT_APP_TYPE
      ).hidePrintoutSections.includes('orderGoods') ? (
        <div>
          {/** Goods Tag List */}
          <div style={{ marginBottom: 15 }}>{printTagList()}</div>
          {/** 特殊商品列表 */}
          {printSpecialItems()}
        </div>
      ) : (
        printoutTableData.table.length > 0 && (
          <>
            <Table
              className="tablePrintout"
              dataSource={printoutTableData.table}
              rowKey={(data, index) => `${index}`}
              columns={columns.filter(
                (x) =>
                  (showPrice || x.key !== 'goodsPrice') &&
                  (showPrice || x.key !== 'totalPrice') &&
                  (!getDashboardStyle().isWholeSaleAppType ||
                    x.key !== 'goodsAttrName') && // 如果是批发不显示这个
                  (getDashboardStyle().isWholeSaleAppType ||
                    x.key !== 'feesInfo') // 如果不是批发就不显示这个
              )}
              pagination={false}
              size="small"
              bordered={true}
              style={{
                marginBottom: 15,
                borderColor: BLACK,
              }}
            />
          </>
        )
      )}

      <div /**Price Breakdown Body */
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          fontSize: PRINTOUT_DATA_FONT_SIZE,
        }}
      >
        {/**Subtotal Breakdown */}
        {isFullVersion && (
          <div>
            <span>{`${t(
              'order.printoutComponents.printoutTableTotalGoodsNumber'
            )}: ${printoutTableData.totalGoodsNumber}`}</span>
            {showPrice && (
              <span>{`${t(
                'order.printoutComponents.printoutTableSubtotal'
              )}: $${printoutTableData.orderAmount.goodsAmount}`}</span>
            )}
          </div>
        )}

        {/**Total Amount Breakdown */}
        {showPrice && (
          <>
            <div>
              {priceABlock.map((pricePart: string) => {
                let amount: string = String(
                  printoutTableData.orderAmount[
                    pricePart as keyof typeof printoutTableData.orderAmount
                  ]
                );
                if (
                  amount !== '0.00' ||
                  pricePart === 'goodsAmount' ||
                  pricePart === 'totalAmount'
                ) {
                  if (pricePart === 'goodsAmount') {
                    return ` ${t(
                      'order.orderListColumns.orderAmount.goodsAmount'
                    )}: $${amount}`;
                  } else if (pricePart === 'depositFee') {
                    return ` + ${t(
                      'order.orderListColumns.orderAmount.depositFee'
                    )}: $${amount}`;
                  } else if (pricePart === 'recycleFee') {
                    return ` + ${t(
                      'order.orderListColumns.orderAmount.recycleFee'
                    )}: $${amount}`;
                  } else if (pricePart === 'discountAmount') {
                    return ` - ${t(
                      'order.orderListColumns.orderAmount.discountAmount'
                    )}: $${amount}`;
                  } else if (pricePart === 'totalAmount') {
                    return ` = ${t(
                      'order.orderListColumns.orderAmount.totalAmount'
                    )}: $${amount}`;
                  } else {
                    return ` + ${t(
                      `order.orderListColumns.orderAmount.${pricePart}`
                    )}: $${amount}`;
                  }
                } else {
                  return '';
                }
              })}
            </div>
            <div /**Order Amount Breakdown */>
              {priceBBlock.map((pricePart: string) => {
                let amount: string = String(
                  printoutTableData.orderAmount[
                    pricePart as keyof typeof printoutTableData.orderAmount
                  ]
                );
                if (
                  amount !== '0.00' ||
                  pricePart === 'orderAmount' ||
                  pricePart === 'paidAmount' ||
                  pricePart === 'totalAmount'
                ) {
                  if (pricePart === 'totalAmount') {
                    return `${t(
                      'order.orderListColumns.orderAmount.totalAmount'
                    )}: $${amount}`;
                  } else if (pricePart === 'returnAmount') {
                    return ` + ${t(
                      'order.orderListColumns.orderAmount.returnAmount'
                    )}: $${amount}`;
                  } else if (pricePart === 'orderAmount') {
                    return ` = ${t(
                      'order.orderListColumns.orderAmount.orderAmount'
                    )}: $${amount}`;
                  } else {
                    return ` - ${t(
                      `order.orderListColumns.orderAmount.${pricePart}`
                    )}: $${amount}`;
                  }
                } else {
                  return '';
                }
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderPrintoutTable;
