/**General Constants Usage */
export const DASHBOARD_VERSION = '2025-01-24 11:35';

/** Date-Time Format */
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WORDS_FORMAT = 'MMMM d, YYYY';
export const DATE_WORDS_SHORTCUT_FORMAT = 'MMM D, YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**Font Sizes */
export const XSMALL_FONT_SIZE = 11;
export const DEFAULT_FONT_SIZE = 14;
export const SMALL_FONT_SIZE = 15;
export const MEDIUM_FONT_SIZE = 17;
export const LARGE_FONT_SIZE = 20;
export const X_LARGE_FONT_SIZE = 30;
export const DEFAULT_SIZE_TYPE = 'small';

/*** Request Timeout */
export const SHORTEST_TIMEOUT = 50;
export const SHORT_TIMEOUT = 150;
export const GENERAL_TIMEOUT = 300;
export const EXTENDED_TIMEOUT = 500;
export const LONG_TIMEOUT = 800;
export const LONGEST_TIMEOUT = 1000;
export const MAX_REQUEST_TIMEOUT = 30000;

/*** Files Path */
export const FILES_ORDER_IMPORT_TEMPLATE =
  'https://image.luniumall.com/default/files/order-import-template.xls';
