import { Descriptions, Image, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoodData, Promote, PurchaseLimit } from '../../../types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { GREEN1, RED1 } from '../../../constants/color';
import { FALLBACK_IMG } from '../../../constants/styles';

type GoodsOverviewProps = {
  goodInfo: GoodData;
  isMainGoods: boolean;
};

const GoodsOverview = ({ goodInfo, isMainGoods }: GoodsOverviewProps) => {
  const { t } = useTranslation();
  const labels: Array<keyof GoodData> = [
    'goodsId',
    'goodsNumber',
    'goodsUnit',
    'gst',
    'pst',
    'isOnSale',
    'shopPrice',
  ];
  const promoteGoodsLabel: Array<keyof Promote> = ['isPromote', 'promotePrice'];
  const purchaseLimitGoodsLabel: Array<keyof PurchaseLimit> = [
    'isPurchaseLimit',
    'purchaseLimitNum',
  ];

  const renderDescriptionItems = (
    labelKeys: string[],
    data: any,
    translationKey: string
  ) => {
    return labelKeys.map((label) => (
      <Descriptions.Item
        key={label}
        labelStyle={{ fontWeight: 'bold' }}
        label={`${t(`${translationKey}.${label}`)} `}
      >
        {typeof data[label] === 'boolean'
          ? booleanSwitch(data[label])
          : data[label]?.toString() || 'N/A'}
      </Descriptions.Item>
    ));
  };

  const renderItemDate = (
    label: string,
    startTime: string,
    endTime: string
  ) => {
    return (
      <Descriptions.Item
        label={`${t(`${label}`)} `}
        labelStyle={{ fontWeight: 'bold' }}
        span={2}
      >
        {startTime ? `${startTime} - ${endTime}` : 'N/A'}
      </Descriptions.Item>
    );
  };

  const booleanSwitch = (isChecked: boolean) => {
    return isChecked ? (
      <CheckOutlined style={{ color: GREEN1 }} />
    ) : (
      <CloseOutlined style={{ color: RED1 }} />
    );
  };

  const title = `${
    isMainGoods
      ? t('goods.goodsListColumns.mainGoodsName')
      : t('goods.goodsListColumns.goodsName')
  }: ${goodInfo.goodsName}`;

  const titleWithImage = (
    <Space>
      <Image
        src={goodInfo.thumbPicPath}
        fallback={FALLBACK_IMG}
        style={{ maxWidth: 100 }}
        preview={{ src: goodInfo.largePicPath }}
      />
      <Typography.Text>{title}</Typography.Text>
    </Space>
  );

  return (
    <Descriptions
      title={
        goodInfo.thumbPicPath && goodInfo.largePicPath ? titleWithImage : title
      }
      layout="vertical"
      bordered
      // column={2}
      size="small"
    >
      {renderDescriptionItems(labels, goodInfo, 'goods.goodsListColumns')}

      {goodInfo.promote && renderDescriptionItems(
        promoteGoodsLabel,
        goodInfo.promote,
        'goods.goodsListColumns.promote'
      )}

      {goodInfo.promote && renderItemDate(
        `goods.goodsListColumns.promote.promoDate`,
        goodInfo.promote.promoteStartDate,
        goodInfo.promote.promoteEndDate
      )}

      {goodInfo.purchaseLimit && renderDescriptionItems(
        purchaseLimitGoodsLabel,
        goodInfo.purchaseLimit,
        'goods.goodsListColumns.purchaseLimit'
      )}

      {goodInfo.purchaseLimit && renderItemDate(
        `goods.goodsListColumns.purchaseLimit.purchaseLimitDate`,
        goodInfo.purchaseLimit.purchaseLimitStartDate,
        goodInfo.purchaseLimit.purchaseLimitEndDate
      )}
    </Descriptions>
  );
};

export default GoodsOverview;
