import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GENERAL_TIMEOUT } from '../../../constants/systemConstants';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import BrandDropdown from '../common/BrandDropdown';
import CategoryDropdown from '../common/CategoryDropdown';
import SelectGoodAttrModal from './SelectGoodAttrModal';
import SelectGoodsGalleryGrid from './SelectGoodsGalleryGrid';

/** NOTE README
 * @param setNewGoodObjs
 * - It is the array of selected Good Objs the user has made.
 * - It does NOT account for duplicates or any special requirements.
 * - It will be an ARRAY of Good Objs MOST of the times. On special cases it can be a CUSTOM type.
 * - in multi-select mode -> ARRAY of Good Objs
 * - not in multi-select mode -> either Good Obj OR custom type
 * - IF the array is empty, that means the user has NOT select any objs. You need to handle the case for empty arrays.
 * - If you want to remove duplicates or handle any unique cases, then you need you need to do it in the useEffect outside the SelectGoodsModal
 * - TRY TO HANDLE CHANGES OUTSIDE OF THIS MODAL!!!! OrderGoods is an exception
 */

type SelectGoodsModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  newGoodObjs?: any;
  setNewGoodObjs: React.Dispatch<React.SetStateAction<any>>;
  multiSelectFeature: boolean;
  firstLoad: boolean;
  sellerId: number;
  userId?: number;
  hideSKU?: boolean;
  shippingTransportId?: number;
  orderId?: number;
  callback?: (newGoodObjs: any) => void;
};

const SelectGoodsModal = ({
  visible,
  setVisible,
  newGoodObjs,
  setNewGoodObjs,
  multiSelectFeature,
  sellerId,
  userId,
  hideSKU,
  shippingTransportId,
  orderId,
  callback,
}: SelectGoodsModalProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const isSubscribed = useRef(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [calledIndicator, setCalledIndicator] = useState(false);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});

  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Data Components
  const [goodData, setGoodData] = useState<GoodData[]>([]);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<any[]>([]);
  const screens = Grid.useBreakpoint();
  const [selectGoodAttrModalVisible, setSelectGoodAttrModalVisible] =
    useState<boolean>(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Fetch goods List
  const getGoodsData = useCallback(
    (p?: number, pSize?: number) => {
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken('goods/list', {
        params: {
          page: p ? p : 1,
          size: pSize ? pSize : 12,
          sellerId: sellerId,
          catId:
            formRef.current && form.getFieldValue('catId')
              ? form.getFieldValue('catId').at(-1)
              : undefined,
          brandId:
            formRef.current && form.getFieldValue('brandId')
              ? form.getFieldValue('brandId')
              : undefined,
          keyword:
            formRef.current && form.getFieldValue('keyword')
              ? form.getFieldValue('keyword')
              : undefined,
          isOnSale:
            formRef.current &&
            typeof form.getFieldValue('isOnSale') === 'boolean'
              ? form.getFieldValue('isOnSale')
              : undefined,
        },
      })
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              if (isSubscribed.current) {
                setGoodData(response.data.list);
                setTotal(response.data.totalItem);
                setCalledIndicator(true);
              }
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
          if (isSubscribed.current) setIsLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setIsLoading(false);
          console.log(err);
        });
    },
    [t, form, sellerId]
  );

  useEffect(() => {
    if (visible && !calledIndicator) {
      getGoodsData(page, pageSize);
    }
  }, [getGoodsData, calledIndicator, visible, page, pageSize]);

  const onClose = () => {
    if (isSubscribed.current) {
      setPage(1);
      setVisible(false);
      setAdvance({});
      setGoodData([]);
      setCalledIndicator(false);
      setSelectedGoodObjs([]);
    }
  };
  const onFinish = () => {
    if (
      shippingTransportId !== undefined &&
      selectedGoodObjs.length &&
      hasPermission(actionPermissions.orderGroup.orderManage)
    ) {
      setSelectGoodAttrModalVisible(true);
    } else {
      if (selectedGoodObjs.length === 0) {
        setSelectedGoodObjs([]);
      } else {
        multiSelectFeature
          ? setNewGoodObjs(selectedGoodObjs)
          : setNewGoodObjs(selectedGoodObjs[0]);
      }
      callback && callback(selectedGoodObjs);
      onClose();
    }
  };

  const onFinishAttr = () => {
    setSelectedGoodObjs([]);
    setVisible(false);
  };

  return (
    <Modal
      width={screens.lg ? 992 : screens.md ? 768 : undefined}
      title={
        <Typography.Text>
          {t('goods.add/editGood.selectGoodsTitle')}
        </Typography.Text>
      }
      visible={visible}
      okText={t('goods.add/editDepartment.ok')}
      cancelText={t('goods.add/editDepartment.cancel')}
      onOk={onFinish}
      okButtonProps={{
        disabled: selectedGoodObjs && selectedGoodObjs.length ? false : true,
      }}
      onCancel={onClose}
      bodyStyle={{ padding: '12px 24px' }}
    >
      <Form form={form} ref={formRef} layout="vertical" initialValues={{ isOnSale: true }}>
        <Row justify="start" gutter={[8, 8]}>
          <Col span={12} lg={6}>
            <Form.Item
              label={t('goods.goodsListColumns.category')}
              name="catId"
              style={{ marginBottom: 0 }}
            >
              <CategoryDropdown
                onFocusFetch={true}
                onChange={(x) => {
                  setAdvance((prev: any) => ({
                    ...prev,
                    catId: x,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={6}>
            <Form.Item
              label={t('goods.goodsListColumns.brand')}
              name="brandId"
              style={{ marginBottom: 0 }}
            >
              <BrandDropdown
                onChange={(x) => {
                  setAdvance((prev: any) => ({
                    ...prev,
                    brandId: x,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={6}>
            <Form.Item
              label={t('goods.goodsListColumns.keywords')}
              name="keyword"
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={t(
                  'searchPlaceholders.searchNameSnBarcodeKeywords'
                )}
                allowClear
                onChange={(x) => {
                  let target = x.target as HTMLInputElement;
                  setAdvance((prev: any) => ({
                    ...prev,
                    keyword: target.value,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={6}>
            <Form.Item
              label={t('goods.goodsListColumns.isOnSale')}
              name="isOnSale"
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Select
                onChange={(x) => {
                  setAdvance((prev: any) => ({
                    ...prev,
                    isOnSale: x,
                  }));
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                allowClear
                defaultValue={true}  // 默认已上架商品
                filterOption={false}
                optionLabelProp="key"
              >
                <Select.Option
                  key={`${t('general.pleaseSelect')}`}
                  value={undefined}
                >
                  {t('general.pleaseSelect')}
                </Select.Option>
                <Select.Option
                  key={`${t('general.booleanToStatus.true')}`}
                  value={true}
                >
                  {t('general.booleanToStatus.true')}
                </Select.Option>
                <Select.Option
                  key={`${t('general.booleanToStatus.false')}`}
                  value={false}
                >
                  {t('general.booleanToStatus.false')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
          marginBottom: 10,
          flexWrap: 'wrap',
        }}
      >
        <Space direction="horizontal">
          <Space align="start" size={3}>
            <Button
              onClick={() => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => {
                    setCalledIndicator(false);
                  }, GENERAL_TIMEOUT)
                );
              }}
              size="small"
              type="text"
              style={{ padding: 0 }}
              icon={<ReloadOutlined />}
            ></Button>
            <Typography.Text>{`${t('general.refresh')} - ${
              showSelected ? selectedGoodObjs.length : total
            } Record${
              (!showSelected && total > 0) ||
              (showSelected && selectedGoodObjs.length > 2)
                ? 's'
                : ''
            }`}</Typography.Text>
          </Space>
          <Checkbox
            style={{ padding: 0 }}
            onChange={(value) => {
              setShowSelected(value.target.checked);
            }}
          >
            {t('goods.goodsListColumns.showSelected')}
            <Typography.Text
              style={{
                display:
                  selectedGoodObjs && selectedGoodObjs.length === 0
                    ? 'none'
                    : '',
              }}
            >{` (${
              selectedGoodObjs && selectedGoodObjs.length
            } Selected)`}</Typography.Text>
          </Checkbox>
        </Space>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  getGoodsData();
                  setCalledIndicator(true);
                  setPage(1);
                }, GENERAL_TIMEOUT)
              );
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.search')}
          </Button>
          <Button
            disabled={Object.values(advance).every((value) => !value)}
            onClick={() => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setPage(1);
              setTypingTimeout(
                setTimeout(() => {
                  setAdvance({});
                  form.resetFields();
                  setCalledIndicator(false);
                }, GENERAL_TIMEOUT)
              );
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.reset')}
          </Button>
        </Space>
      </Space>
      {selectedGoodObjs && (
        <SelectGoodsGalleryGrid
          multiSelectFunct={multiSelectFeature}
          selectedGoodObjs={selectedGoodObjs}
          setSelectedGoodObjs={setSelectedGoodObjs}
          showSelected={showSelected}
          loading={isLoading}
          hideSKU={hideSKU}
          goodData={goodData}
          shippingTransportId={shippingTransportId}
        />
      )}
      <div
        style={{ marginBottom: 15, display: 'flex', justifyContent: 'right' }}
      >
        <Pagination
          current={page}
          style={{ marginTop: 15 }}
          pageSize={pageSize}
          size="small"
          showQuickJumper
          total={total}
          showTotal={(total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            })
          }
          showSizeChanger={false}
          onChange={(pageNumber, pageSize) => {
            setPageSize(pageSize);
            setPage(pageNumber);
            getGoodsData(pageNumber, pageSize);
            setCalledIndicator(true);
          }}
        />
      </div>
      <SelectGoodAttrModal
        visible={selectGoodAttrModalVisible}
        setVisible={setSelectGoodAttrModalVisible}
        selectedGoods={newGoodObjs}
        setSelectedGoods={setNewGoodObjs}
        callBack={onFinishAttr}
        userId={userId || 0}
        currentGood={selectedGoodObjs[0]}
        orderId={orderId}
      />
    </Modal>
  );
};

export default SelectGoodsModal;
