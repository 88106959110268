import { Button, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { actionPermissions } from '../constants/actionPermissions';
import { PrintType, PrintTypeMode } from '../types';
import { alertMessage } from '../utils/alertMessage';
import { hasPermission } from '../utils/hasPermission';
import { ComponentToPrint } from '../views/order/ComponentToPrint';

type GeneralPrintContentProps<T extends PrintType> = {
  mode: PrintTypeMode;
  printoutTableData: T;
  onCancel: Function;
  actionPermission?: string;
  isLandscape?: boolean;
  isZoom?: boolean;
  isFullVersion?: boolean;
  beforePrint?: {
    function: Function;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
  };
};

const GeneralPrintContent = <T extends PrintType>({
  mode,
  printoutTableData,
  onCancel,
  actionPermission = actionPermissions.orderGroup.orderPrint,
  isLandscape = false,
  isZoom = false,
  isFullVersion = true,
  beforePrint,
}: GeneralPrintContentProps<T>) => {
  //General Components
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const ref = useRef<any>();
  const isSubscribed = useRef(true);
  // For Statement
  const [date, setDate] = useState<string>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    isSubscribed.current = false;
  }, []);

  const disableButton = () => {
    const disable = actionPermission ? !hasPermission(actionPermission) : false;
    if (printoutTableData.statementData) {
      return disable || date === undefined;
    }
    return disable;
  };

  const reactPrint = () => {
    return (
      <ReactToPrint
        key={1}
        pageStyle={pageStyle(isLandscape)}
        documentTitle={mode}
        content={() => ref.current}
        onBeforePrint={() =>
          beforePrint
            ? beforePrint.function()
            : alertMessage('info', t('order.alerts.printing'))
        }
        onPrintError={() =>
          alertMessage('error', t('order.alerts.printFailed'))
        }
        trigger={() => (
          <Button disabled={disableButton()} type="primary" key={1}>
            {t('actionsColumn.print')}
          </Button>
        )}
      ></ReactToPrint>
    );
  };

  const componentPrint = () => {
    switch (mode) {
      case 'order':
        // Batch Print Orders
        if (printoutTableData.ordersData) {
          return printoutTableData.ordersData.map((order, index) => {
            return pageBreakContent(
              index,
              <ComponentToPrint
                orderData={order}
                footerData={order.footer}
                isFullVersion={isFullVersion}
              />
            );
          });
        }
        break;
      case 'invoice':
        if (printoutTableData.ordersData) {
          return printoutTableData.ordersData.map((order, index) => {
            return pageBreakContent(
              index,
              <ComponentToPrint invoiceData={order}/>
            );
          });
        }
        break;
      case 'packingSlip':
        if (printoutTableData.ordersData) {
          return printoutTableData.ordersData.map((order, index) => {
            return pageBreakContent(
              index,
              <ComponentToPrint packingSlipData={order} />
            );
          });
        }
        break;
      case 'driver':
        if (printoutTableData.driverData) {
          return <ComponentToPrint driverData={printoutTableData.driverData} />;
        }
        break;
      case 'statement':
        if (printoutTableData.statementData) {
          return (
            <ComponentToPrint
              statementData={printoutTableData.statementData}
              setDate={setDate}
            />
          );
        }
        break;
      case 'orderClaim':
        // Batch Print
        if (printoutTableData.orderClaimsData) {
          return printoutTableData.orderClaimsData.map((claim, index) => {
            return pageBreakContent(
              index,
              <ComponentToPrint orderClaimData={claim} />
            );
          });
        }
        // Single
        else if (printoutTableData.orderClaimData) {
          return (
            <ComponentToPrint
              orderClaimData={printoutTableData.orderClaimData}
            />
          );
        }
        break;
      case 'printLabel':
        if (printoutTableData.printLabel) {
          return <ComponentToPrint printLabel={printoutTableData.printLabel} />;
        }
        break;
      case 'orderGoods':
        if (printoutTableData.orderGoodsData) {
          return (
            <ComponentToPrint
              orderGoodsData={printoutTableData.orderGoodsData}
            />
          );
        }
        break;
      case 'excel':
        if (printoutTableData.excelData) {
          return <ComponentToPrint excelData={printoutTableData.excelData} />;
        }
        break;
    }

    return <></>;
  };

  const renderContent = () => {
    return (
      <>
        <div
          style={{
            width: '100%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {reactPrint()}
        </div>

        <div
          style={{
            zoom: isZoom ? (screens.sm ? 0.8 : 0.6) : 1,
            padding: 0,
            width: '100%',
          }}
          ref={ref}
        >
          <div style={{ margin: 0 }}>{componentPrint()}</div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          {reactPrint()}
          <Button key={0} onClick={onCancel()} type="link">
            {t('order.print.cancel')}
          </Button>
        </div>
      </>
    );
  };

  return beforePrint ? (
    <Spin spinning={beforePrint.isLoading}>{renderContent()}</Spin>
  ) : (
    renderContent()
  );
};

export default GeneralPrintContent;

const pageStyle = (isLandscape: boolean) => {
  const size = isLandscape ? 'landscape' : 'portrait';
  return `@page { size: ${size}; margin: 5mm;}`;
};

// 批量打印，分页处理
const pageBreakContent = (index: number, content: JSX.Element) => {
  return (
    <div style={{ margin: 0 }} key={index}>
      <div className="page-break" />
      {content}
    </div>
  );
};
