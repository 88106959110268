import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  InputNumber,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import GoodPendingStock from './GoodPendingStock';
import GoodsOverview from './GoodsOverview';

type GoodPriceProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const GoodPrice = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodPriceProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [isWeightGood, setIsWeightGood] = useState(false);
  const [popOverVis, setPopOverVis] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (goodInfo && goodInfo.isWeightGoods && isSubscribed.current) {
      setIsWeightGood(true);
    }
  }, [goodInfo]);

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then(async (values) => {
          if (isSubscribed.current) setLoading(true);
          postDataWithAuthToken('goods/edit', {
            ...values,
            goodsId: goodInfo && goodInfo.goodsId,
            warnNumber: values.warnNumber || 0,
            oldGoodsNumber:
              goodInfo && !goodInfo.isRelatedGoods
                ? goodInfo.goodsNumber
                : undefined,
            goodsNumber:
              goodInfo && !goodInfo.isRelatedGoods
                ? values.goodsNumber
                : undefined,
            depositFee: values.depositFee || 0,
            recycleFee: values.recycleFee || 0,
          })
            .then(async (response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.goodsEdited'));
                handleCancelEditing();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    form.resetFields();
    if (isSubscribed.current) {
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form
        form={form}
        initialValues={{
          ...goodInfo,
          warnNumber: goodInfo && goodInfo.warnNumber ? goodInfo.warnNumber : 3,
        }}
      >
        <Row gutter={[24, 8]}>
          <Col span={16}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.goodsListColumns.shopPrice')}
            </Typography.Text>
            {goodInfo.mainGoods && (
              <Popover
                trigger={'click'}
                placement="right"
                content={<GoodsOverview goodInfo={goodInfo.mainGoods} isMainGoods = {true}/>}
              >
                <ProfileOutlined style={{ padding: 5, fontSize: '16px' }} />
              </Popover>
            )}
          </Col>
          <Col span={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editing ? (
                <Space>
                  <Button onClick={() => handleCancelEditing()}>
                    {t('goods.add/editGood.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                  >
                    {t('goods.add/editGood.ok')}
                  </Button>
                </Space>
              ) : (
                <Tooltip
                  title={t('goods.add/editGood.edit')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name={editing ? 'shopPrice' : undefined}
              label={t('goods.goodsListColumns.shopPrice')}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseInputAmount'),
                },
              ]}
            >
              {editing ? (
                <InputNumber type="number" min={0} />
              ) : (
                <>{goodInfo.shopPrice}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name={editing ? 'gst' : undefined}
              label={t('goods.goodsListColumns.gst')}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseInputAmount'),
                },
              ]}
              tooltip={editing && t('goods.add/editGood.toolTip.gst')}
            >
              {editing ? (
                <InputNumber type="number" min={0} addonAfter="%" />
              ) : (
                <>{`${goodInfo.gst}%`}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name={editing ? 'pst' : undefined}
              label={t('goods.goodsListColumns.pst')}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseInputAmount'),
                },
              ]}
            >
              {editing ? (
                <InputNumber type="number" min={0} addonAfter="%" />
              ) : (
                <>{`${goodInfo.pst}%`}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsNumber')}
              name={editing ? 'goodsNumber' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseInputAmount'),
                },
              ]}
            >
              {editing && !goodInfo.sku && !goodInfo.isRelatedGoods ? (
                <InputNumber type="number" min={0} />
              ) : (
                <Space>
                  <Typography.Text>{goodInfo.goodsNumber}</Typography.Text>
                  <Popover
                    placement="top"
                    visible={popOverVis}
                    trigger="click"
                    onVisibleChange={(visible) => {
                      !visible && setPopOverVis(false);
                    }}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    content={
                      <GoodPendingStock
                        visible={popOverVis}
                        goodsId={goodInfo.goodsId}
                      />
                    }
                  >
                    <Button
                      block
                      type="text"
                      onClick={() => {
                        setPopOverVis(true);
                      }}
                    >
                      <InfoCircleOutlined style={{ fontSize: '16px' }} />
                    </Button>
                  </Popover>
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.add/editGood.warnNumber')}
              name={editing ? 'warnNumber' : undefined}
              style={{ marginBottom: 0 }}
              tooltip={editing && t('goods.add/editGood.toolTip.warnNumber')}
            >
              {editing ? (
                <InputNumber type="number" min={0} />
              ) : (
                <>{goodInfo.warnNumber}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.salesVolume')}
              style={{ marginBottom: 0 }}
            >
              <>{goodInfo.salesVolume}</>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isWeightGoods"
              label={t('goods.goodsListColumns.isWeightGoods')}
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch
                disabled={!editing || goodInfo.isRelatedGoods}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setIsWeightGood(checked);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name={
                editing && !goodInfo.isRelatedGoods ? 'goodsWeight' : undefined
              }
              label={t('goods.add/editGood.goodsWeight')}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: isWeightGood,
                  message: t('general.inputError.pleaseInputAmount'),
                },
              ]}
            >
              {editing && !goodInfo.isRelatedGoods ? (
                <InputNumber type="number" min={0} />
              ) : (
                <>{goodInfo.goodsWeight}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.estimateShopPrice')}
              style={{ marginBottom: 0 }}
            >
              <>{goodInfo.estimateShopPrice}</>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.depositFee')}
              name={editing ? 'depositFee' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <InputNumber type="number" min={0} />
              ) : (
                <>{goodInfo.depositFee}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.recycleFee')}
              name={editing ? 'recycleFee' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <InputNumber type="number" min={0} />
              ) : (
                <>{goodInfo.recycleFee}</>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.goodsListColumns.shopPrice')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default GoodPrice;
