import React, { useEffect, useRef, useState } from 'react';
import {
  Empty,
  Button,
  Form,
  Input,
  Row,
  Col,
  Space,
  Typography,
  Tooltip,
  Spin,
  Popover,
  Popconfirm,
  Switch,
} from 'antd';
import { GoodData } from '../../../types';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ProfileOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import GoodsOverview from './GoodsOverview';
import SelectGoodsModal from '../selectGoods/SelectGoodsModal';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { PRIMARY } from '../../../constants/color';
import { actionPermissions } from '../../../constants/actionPermissions';
import { hasPermission } from '../../../utils/hasPermission';

type GoodDescriptionProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Displays User Information
 *
 * @param userInfo User Information to display
 */
const GoodDescription = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodDescriptionProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectGoodsVisible, setSelectGoodsVisible] = useState<boolean>(false);
  const [mainGoodsId, setMainGoodsId] = useState<GoodData>();
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData>();
  const { addTab } = useTab();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * When the changes have been made in the SelectedGoodObjs, it will update mainGoodsId. The user only sees mainGoodsId.
   * The merging be done here
   */
  useEffect(() => {
    if (selectedGoodObjs) {
      setMainGoodsId(selectedGoodObjs);
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs]);

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setLoading(true);
          postDataWithAuthToken('goods/edit', {
            ...values,
            goodsId: goodInfo && goodInfo.goodsId,
            mainGoodsId: mainGoodsId?.goodsId,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.goodsEdited'));
                handleCancelEditing();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    form.setFieldsValue({ ...goodInfo });
    if (isSubscribed.current) {
      setMainGoodsId(undefined);
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  const syncWithMainGood = () => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('goods/related_goods/sync', {
      goodsIds: goodInfo ? [goodInfo.goodsId] : undefined,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            alertMessage('success', t('goods.alerts.goodSynced'));
            callBack && callBack();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form form={form} initialValues={{ ...goodInfo }}>
        <Row gutter={[24, 8]}>
          <Col span={12} lg={16}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name={editing ? 'goodsName' : undefined}
              rules={[
                { required: true, message: t('general.inputError.empty') },
              ]}
            >
              {editing ? <Input /> : <>{goodInfo.goodsName}</>}
            </Form.Item>
          </Col>
          <Col span={12} lg={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editing ? (
                <Space>
                  <Button onClick={() => handleCancelEditing()}>
                    {t('goods.add/editGood.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                  >
                    {t('goods.add/editGood.ok')}
                  </Button>
                </Space>
              ) : (
                <Tooltip
                  title={t('goods.add/editGood.edit')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsId')}
              style={{ marginBottom: 0 }}
            >
              {goodInfo.goodsId}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.addTime')}
              style={{ marginBottom: 0 }}
            >
              {goodInfo.addTime}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.lastUpdate')}
              style={{ marginBottom: 0 }}
            >
              {goodInfo.lastUpdate}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsUnit')}
              name={editing ? 'goodsUnit' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                { required: true, message: t('general.inputError.empty') },
              ]}
              tooltip={editing && t('goods.add/editGood.toolTip.goodsUnit')}
            >
              {editing ? <Input /> : <>{goodInfo.goodsUnit}</>}
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.barcode')}
              name={editing ? 'barcode' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? <Input /> : goodInfo.barcode}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.supplierSku')}
              name={editing ? 'supplierSku' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? <Input /> : goodInfo.supplierSku}
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsSn')}
              name={editing ? 'goodsSn' : undefined}
              style={{ marginBottom: 0 }}
              tooltip={editing && t('goods.add/editGood.toolTip.goodsSn')}
            >
              {editing ? <Input /> : goodInfo.goodsSn}
            </Form.Item>
          </Col>

          {goodInfo.seller.sellerId !== 0 && (
            <Col span={24} md={12} lg={8}>
              <Form.Item
                label={t('goods.goodsListColumns.mainGoodsId')}
                style={{ marginBottom: 0 }}
              >
                {/**Case for when not in editing mode, just display the mainGoodsId */}
                {!editing && goodInfo.mainGoods && (
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.goodView)
                    }
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.detail}?good_id=${goodInfo.mainGoods.goodsId}`
                      )
                    }
                  >
                    {goodInfo.mainGoods.goodsId}
                  </Button>
                )}
                {/**Case for when in editing mode and the user just selected a new mainGoodsId. Display the new ID instead of the old ID */}
                {editing && !goodInfo.mainGoods && mainGoodsId && (
                  <Button
                    type="link"
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.goodView)
                    }
                    style={{ padding: 0 }}
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.detail}?good_id=${mainGoodsId}`
                      )
                    }
                  >
                    {mainGoodsId}
                  </Button>
                )}

                {/**This is for whether the user selected a goodsId, so that it'll determine whether to display + or edit icon.
                 * If the good did not have any mainGoodId, then it'll display a + icon button in Edit mode
                 */}
                {editing &&
                  !goodInfo.mainGoods &&
                  goodInfo.seller.sellerId !== 0 && (
                    <Button
                      icon={mainGoodsId ? <EditOutlined /> : <PlusOutlined />}
                      onClick={() => setSelectGoodsVisible((prev) => !prev)}
                      type="text"
                    ></Button>
                  )}

                {/**IF prev mainGoodsId exists, this is for either display newly selected mainGoods ID or previous mainGoodsID
                 * text button in Edit mode*/}
                {editing && goodInfo.mainGoods && (
                  <Button
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.goodView)
                    }
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.detail}?good_id=${
                          mainGoodsId !== undefined
                            ? mainGoodsId
                            : goodInfo.mainGoods.goodsId
                        }`
                      )
                    }
                    style={{ padding: 0 }}
                    type="link"
                  >
                    {mainGoodsId
                      ? mainGoodsId.goodsId
                      : goodInfo.mainGoods.goodsId}
                  </Button>
                )}
                {/**IF prev mainGoodId exists, it'll display either Edit + Close icon buttons or
                 * mainGoodsOverview in Popover. If the user clicks Close, there will be a warning */}
                {editing && goodInfo.mainGoods ? (
                  <>
                    <Tooltip title={t('actionsColumn.edit')}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                          setSelectGoodsVisible((prev) => !prev);
                        }}
                        type="text"
                      />
                    </Tooltip>
                    <Tooltip title={t('actionsColumn.delete')}>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => setMainGoodsId(undefined)}
                        type="text"
                        danger
                      />
                    </Tooltip>
                  </>
                ) : (
                  goodInfo.mainGoods && (
                    <>
                      <Popover
                        trigger={'click'}
                        placement="right"
                        content={<GoodsOverview goodInfo={goodInfo.mainGoods} isMainGoods = {true}/>}
                      >
                        <Button icon={<ProfileOutlined />} type="text" />
                      </Popover>
                      {goodInfo && goodInfo.isRelatedGoods && (
                        <Popconfirm
                          title={t('goods.goodsBatch.syncWithMainGoodWarning')}
                          okText={t('actionsColumn.confirmation.yes')}
                          cancelText={t('actionsColumn.confirmation.no')}
                          onConfirm={syncWithMainGood}
                        >
                          <Tooltip
                            title={t('goods.goodsBatch.syncWithMainGood')}
                          >
                            <Button icon={<SyncOutlined />} type="text" />
                          </Tooltip>
                        </Popconfirm>
                      )}
                    </>
                  )
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.goodsListColumns.seller')}
              style={{ marginBottom: 0 }}
            >
              {goodInfo.seller && (
                <Typography.Text style={{ color: PRIMARY }}>
                  {goodInfo.seller.shopName}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              label={t('goods.add/editGood.isSync')}
              name="isSync"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('goods.goodsListColumns.keywords')}
              name={editing ? 'keywords' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? <Input /> : goodInfo.keywords}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsBrief')}
              name={editing ? 'goodsBrief' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
              ) : (
                goodInfo.goodsBrief
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/**This Select Goods component is in Good Detail. When activated from here, the sellerId will be 0 */}
      <SelectGoodsModal
        firstLoad={false}
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectGoodsVisible}
        setVisible={setSelectGoodsVisible}
        sellerId={0}
        multiSelectFeature={false}
      />
    </Spin>
  ) : (
    <Empty />
  );
};

export default GoodDescription;
