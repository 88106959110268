import { PRINTOUT_NEWLINE } from '../../constants/printoutConstants';
import {
  GoodsTagMap,
  OrderObjTable,
  OrderPrintOutContents,
  OrderTable,
  PrintoutOrderBodyData,
  PrintoutOrderFooterData,
  PrintoutOrderHeaderData,
  PrintoutOrderTableData,
  PrintoutSelectedOrderData,
  PrintoutTable,
} from '../../types';
import getDashboardStyle from '../../utils/getDashboardStyle';

/**
 *
 * @param goodsTagMap This contains the data for the printout
 * @returns an array of objects, reformatted so that it can be used in OrderPrintoutTable
 */

const ToPrintoutTableData = (goodsTagMap: GoodsTagMap, isFull: boolean) => {
  //General Components
  const printoutTableData: PrintoutTable[] = [];
  let catList = Object.keys(goodsTagMap);
  for (var i = 0; i < catList.length; i++) {
    const cat: keyof OrderTable = catList[i];
    let subCatList = Object.keys(goodsTagMap[cat as keyof typeof goodsTagMap]);
    for (var j = 0; j < subCatList.length; j++) {
      const subCat: keyof OrderTable = subCatList[j];
      //Create a divider row
      if (isFull && subCat === PRINTOUT_NEWLINE) {
        printoutTableData.push({});
      } else {
        if (isFull || getDashboardStyle().isWholeSaleAppType) {
          //Create obj for just cat + subCat
          printoutTableData.push({
            cat: j === 0 ? cat : '',
            goodsId: subCat,
          });
          //Create obj for placed orders
          let catVal = goodsTagMap[cat as keyof typeof goodsTagMap];
          const placedOrders: Array<OrderObjTable> =
            catVal[subCat as keyof typeof catVal];
          placedOrders.forEach((order: OrderObjTable) => {
            printoutTableData.push({
              orderId: order.orderId,
              goodsId: order.goodsId,
              goodsName: order.goodsName,
              goodsUnit: order.goodsUnit,
              goodsAttrName: order.goodsAttrName,
              giftSpecial: order.giftSpecial,
              goodsNumber: order.goodsNumber,
              specialGoodsUnit: order.specialGoodsUnit,
              goods: order.goods,
              goodsPrice: order.goodsPrice,
              totalPrice: order.totalPrice,
              gstFee: order.gst,
              pstFee: order.pst,
              depositFee: order.depositFee,
              recycleFee: order.recycleFee,
              goodsWeight: order.goodsWeight,
            });
          });
        } else {
          if (catList.includes('Special') || catList.includes('特殊商品')) {
            if (cat === 'Special' || cat === '特殊商品') {
              //Create obj for just cat + subCat
              printoutTableData.push({
                cat: j === 0 ? cat : '',
                goodsId: subCat,
              });
              //Create obj for placed orders
              let catVal = goodsTagMap[cat as keyof typeof goodsTagMap];
              const placedOrders: Array<OrderObjTable> =
                catVal[subCat as keyof typeof catVal];
              placedOrders.forEach((order: OrderObjTable) => {
                printoutTableData.push({
                  orderId: order.orderId,
                  goodsId: order.goodsId,
                  goodsName: order.goodsName,
                  goodsUnit: order.goodsUnit,
                  goodsAttrName: order.goodsAttrName,
                  giftSpecial: order.giftSpecial,
                  goodsNumber: order.goodsNumber,
                  specialGoodsUnit: order.specialGoodsUnit,
                  goods: order.goods,
                  goodsPrice: undefined,
                  totalPrice: undefined,
                  gstFee: order.gst,
                  pstFee: order.pst,
                  depositFee: order.depositFee,
                  recycleFee: order.recycleFee,
                  goodsWeight: order.goodsWeight,
                });
              });
            }
          }
        }
      }
    }
  }

  return printoutTableData;
};

/**
 * This is where the data is re-constructed into array of objects which are printout data for each order
 */
const BuildOrderData = (
  responseData: OrderPrintOutContents[],
  isFull: boolean
) => {
  let toPrintOrderData: Array<PrintoutSelectedOrderData> = [];
  let orderIds: number[] = [];
  let routeId: number | undefined = undefined;
  responseData.map((content) => {
    orderIds.push(content.orderId);
    if (routeId === undefined && content.route) {
      routeId = content.route.routeId;
    }
    const headerData: PrintoutOrderHeaderData = {
      route: content.route,
      orderShippingLabel: content.orderShippingLabel,
      logo: content.logo,
      consignee: content.orderAddress.consignee,
      shippingTerms: content.shippingTerms,
      qrCode1: content.qrCode1,
      qrCode2: content.qrCode2,
      orderId: content.orderId,
      invoiceSn: content.invoiceSn,
      businessTitle: content.businessTitle,
      businessAddress: content.businessAddress,
      businessName: content.businessName,
      businessPhone: content.businessPhone,
      addTime: content.addTime,
      taskQrCodeUrl: content.taskQrCodeUrl,
    };
    const bodyData: PrintoutOrderBodyData = {
      orderId: content.orderId,
      consignee: content.orderAddress.consignee,
      fullAddress: content.orderAddress.fullAddress,
      shippingName: content.orderShipping.shippingName,
      expectShippingTimeName: content.orderShipping.expectShippingTimeName,
      mobile: content.orderAddress.mobile,
      orderSn: content.orderSn,
      addTime: content.addTime,
      specialUserIcon: content.specialUserIcon,
      payName: content.payName,
      buyerRemark: content.buyerRemark ? content.buyerRemark : undefined,
      sellerRemark: content.sellerRemark ? content.sellerRemark : undefined,
      orderRemark: content.orderRemark ? content.orderRemark : undefined,
      specialUser: content.specialUser ? content.specialUser : undefined,
      buzzerCode: content.orderAddress
        ? content.orderAddress.buzzerCode
        : undefined,
      userMobile: content.user ? content.user.mobilePhone : undefined,
      shippingTerms: content.shippingTerms,
      orderAddress: content.orderAddress,
      orderShipping: content.orderShipping,
      payTime: content.payTime,
      user: content.user,
    };
    const tableData: PrintoutOrderTableData = {
      table: content.goodsTagMap
        ? ToPrintoutTableData(content.goodsTagMap, isFull)
        : [],
      goodsTagList: content.goodsTagList,
      orderAmount: content.orderAmount,
      totalGoodsNumber: content.totalGoodsNumber,
      formValue: content.formValue,
    };
    const footerData: PrintoutOrderFooterData = {
      bottomTitle: content.bottomTitle,
      bottomContents: content.bottomContents,
    };
    return toPrintOrderData.push({
      header: headerData,
      body: bodyData,
      table: tableData,
      footer: footerData,
    });
  });
  return { ordersData: toPrintOrderData, orderIds: orderIds, routeId: routeId };
};

export default BuildOrderData;
