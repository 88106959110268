import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_DEFAULT,
  REVIEW_STATUS_FAILED,
} from '../../../constants/generalConstants';
import { GoodData, GoodEnums } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import GoodsOverview from './GoodsOverview';
import { DATE_FORMAT } from '../../../constants/systemConstants';
import { momentDateTime } from '../../../utils/helperFunction';

type GoodPriceProps = {
  goodInfo?: GoodData;
  goodEnums?: GoodEnums;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const GoodOtherInfo = ({
  goodInfo,
  goodEnums,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodPriceProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [ruleSign, setRuleSign] = useState<string>();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [reviewStatus, setReviewStatus] = useState('');
  const [isReviewEnabled, setIsReviewEnabled] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setLoading(true);
          const { goodsNumberRuleSign, goodsNumberRuleValue, ...rest } = values;
          let goodsNumberRuleObj = {
            sign:
              goodsNumberRuleSign === undefined ? null : goodsNumberRuleSign,
            number:
              goodsNumberRuleValue === undefined ? null : goodsNumberRuleValue,
          };
          postDataWithAuthToken('goods/edit', {
            ...rest,
            goodsNumberRule: goodsNumberRuleObj,
            goodsId: goodInfo && goodInfo.goodsId,
            expireDate: momentDateTime(values.expireDate, DATE_FORMAT, ''),
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.goodsEdited'));
                handleCancelEditing();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    if (isSubscribed.current) {
      if (goodInfo) {
        form.resetFields();
        setReviewStatus(goodInfo.reviewStatus.code);
      }
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  // Check if review is enabled
  const getReviewEnabled = useCallback(() => {
    getDataWithAuthToken('goods/review_enabled')
      .then((response) => {
        if (response && response.goodStatus) {
          setIsReviewEnabled(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  }, [t]);

  useEffect(() => {
    if (goodInfo && isSubscribed.current) {
      setReviewStatus(goodInfo.reviewStatus.code);
      setRuleSign(goodInfo?.goodsNumberRule?.sign);
      getReviewEnabled();
    }
  }, [goodInfo, getReviewEnabled]);

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form
        form={form}
        initialValues={{
          ...goodInfo,
          goodsNumberRuleSign:
            goodInfo && goodInfo.goodsNumberRule !== null
              ? goodInfo.goodsNumberRule.sign
              : undefined,
          goodsNumberRuleValue:
            goodInfo && goodInfo.goodsNumberRule !== null
              ? goodInfo.goodsNumberRule.number
              : undefined,
          sortOrder: goodInfo.sortOrder ? goodInfo.sortOrder : 100,
          isCountForPrint: goodInfo.isCountForPrint || false,
          isPrintForCount: goodInfo.isPrintForCount || false,
          isAloneSale:
            goodInfo.isAloneSale !== undefined ? goodInfo.isAloneSale : true,
          reviewStatus: goodInfo.reviewStatus
            ? goodInfo.reviewStatus.code
            : REVIEW_STATUS_DEFAULT,
          expireDate: momentDateTime(goodInfo.expireDate),
        }}
      >
        <Row gutter={[24, 8]}>
          <Col span={16}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.add/editGood.otherInfo')}
            </Typography.Text>
            {goodInfo.mainGoods && (
              <Popover
                trigger={'click'}
                placement="right"
                content={<GoodsOverview goodInfo={goodInfo.mainGoods} isMainGoods = {true} />}
              >
                <ProfileOutlined style={{ padding: 5, fontSize: '16px' }} />
              </Popover>
            )}
          </Col>
          <Col span={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editing ? (
                <Space>
                  <Button onClick={() => handleCancelEditing()}>
                    {t('goods.add/editGood.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                  >
                    {t('goods.add/editGood.ok')}
                  </Button>
                </Space>
              ) : (
                <Tooltip
                  title={t('goods.add/editGood.edit')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isReal"
              label={t('goods.add/editGood.isReal')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isPresale"
              label={t('goods.goodsListColumns.isPresale')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isOnSale"
              label={t('goods.goodsListColumns.isOnSale')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isAloneSale"
              label={t('goods.add/editGood.isAloneSale')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              tooltip={editing && t('goods.add/editGood.toolTip.isAloneSale')}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isCountForPrint"
              label={t('goods.add/editGood.isCountForPrint')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              tooltip={
                editing && t('goods.add/editGood.toolTip.isCountForPrint')
              }
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col> */}
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isStaffPickupOnly"
              label={t('goods.add/editGood.isStaffPickupOnly')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              tooltip={
                editing && t('goods.add/editGood.toolTip.isStaffPickupOnly')
              }
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name="isDiscountEnabled"
              label={t('goods.add/editGood.isDiscountEnabled')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              tooltip={
                editing && t('goods.add/editGood.toolTip.isDiscountEnabled')
              }
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item
              name={editing ? 'sortOrder' : undefined}
              label={t('goods.goodsListColumns.sortOrder')}
              style={{ marginBottom: 0 }}
            >
              {editing ? <InputNumber type="number" /> : goodInfo.sortOrder}
            </Form.Item>
          </Col>
          <Col span={24} md={editing ? 24 : 12} lg={editing ? 24 : 8}>
            <Space
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 0,
                alignItems: 'center',
              }}
              wrap
            >
              <Form.Item
                label={t('goods.goodsListColumns.goodsNumberRule')}
                style={{ marginBottom: 0 }}
                name={editing ? 'goodsNumberRuleSign' : undefined}
              >
                {editing ? (
                  <Select
                    style={{ minWidth: 100 }}
                    allowClear
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    showSearch={false}
                    filterOption={false}
                    onChange={(value) => {
                      if (value === undefined) {
                        form.setFieldsValue({
                          goodsNumberRuleValue: undefined,
                        });
                      }
                      setRuleSign(value);
                    }}
                  >
                    {goodEnums &&
                      goodEnums.goodsNumberRuleSign.length > 0 &&
                      goodEnums.goodsNumberRuleSign.map((rule) => (
                        <Select.Option key={rule.code} value={rule.code}>
                          {rule.description}
                        </Select.Option>
                      ))}
                  </Select>
                ) : goodEnums &&
                  goodEnums.goodsNumberRuleSign.length > 0 &&
                  goodInfo &&
                  goodInfo.goodsNumberRule ? (
                  <Typography.Text style={{ paddingRight: 10 }}>
                    {goodEnums.goodsNumberRuleSign.map((rule) => {
                      if (rule.code === goodInfo.goodsNumberRule.sign) {
                        return rule.description;
                      } else {
                        return undefined;
                      }
                    })}
                  </Typography.Text>
                ) : (
                  <></>
                )}
              </Form.Item>
              {ruleSign !== undefined && (
                <Form.Item
                  label={t('goods.goodsListColumns.value')}
                  name={editing ? 'goodsNumberRuleValue' : undefined}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: ruleSign !== undefined,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  {editing ? (
                    <InputNumber
                      disabled={ruleSign === undefined}
                      style={{ width: 80 }}
                      type="number"
                      min={1}
                    />
                  ) : (
                    <Typography.Text>
                      {goodInfo && goodInfo.goodsNumberRule !== null
                        ? goodInfo.goodsNumberRule.number
                        : undefined}
                    </Typography.Text>
                  )}
                </Form.Item>
              )}
            </Space>
          </Col>
          <Col span={24} md={12} lg={editing ? 12 : 8}>
            <Form.Item
              name={editing ? 'expireDate' : undefined}
              label={t('goods.goodsListColumns.expireDate')}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <DatePicker
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                />
              ) : (
                goodInfo.expireDate
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={editing ? 12 : 8}>
            <Form.Item
              name={editing ? 'sellerNote' : undefined}
              label={t('goods.add/editGood.sellerNote')}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
              ) : (
                goodInfo.sellerNote
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              name={editing ? 'reviewStatus' : undefined}
              label={t('goods.add/editGood.reviewStatus')}
              style={{ marginBottom: 0 }}
            >
              {isReviewEnabled && editing ? (
                <Radio.Group
                  onChange={(e) => {
                    setReviewStatus(e.target.value);
                  }}
                >
                  {Object.keys(REVIEW_STATUS).map((type) => {
                    return (
                      <Radio key={type} value={type}>
                        {t(REVIEW_STATUS[type])}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              ) : (
                <Tag
                  color={
                    goodInfo.reviewStatus
                      ? goodInfo.reviewStatus.tagColor
                      : undefined
                  }
                >
                  {goodInfo.reviewStatus
                    ? goodInfo.reviewStatus.description
                    : undefined}
                </Tag>
              )}
            </Form.Item>
          </Col>
          {reviewStatus === REVIEW_STATUS_FAILED && (
            <Col span={24} lg={editing ? 12 : 8}>
              <Form.Item
                name={editing ? 'reviewContent' : undefined}
                label={t('goods.add/editGood.reviewContent')}
                style={{ marginBottom: 0 }}
                rules={[
                  { required: true, message: t('general.inputError.empty') },
                ]}
              >
                {editing ? (
                  <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
                ) : (
                  <>{goodInfo.reviewContent}</>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.add/editGood.otherInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default GoodOtherInfo;
