import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Form,
  InputNumber,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import moment from 'moment';
import GoodsOverview from './GoodsOverview';
import { actionPermissions } from '../../../constants/actionPermissions';
import { hasPermission } from '../../../utils/hasPermission';
import { DATE_TIME_FORMAT } from '../../../constants/systemConstants';

type GoodPriceProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const GoodSpecialInfo = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodPriceProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [isPurchaseLimit, setIsPurchaseLimit] = useState(false);
  const [memberPriceEmpty, setMemberPriceEmpty] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (goodInfo && isSubscribed.current) {
      if (goodInfo.promote) setIsPromote(goodInfo.promote.isPromote);
      if (goodInfo.purchaseLimit)
        setIsPurchaseLimit(goodInfo.purchaseLimit.isPurchaseLimit);
      if (
        !goodInfo.memberPrice ||
        parseFloat(goodInfo.memberPrice.toString()) === 0
      )
        setMemberPriceEmpty(true);
    }
  }, [goodInfo]);

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setLoading(true);
          postDataWithAuthToken('goods/edit', {
            goodsId: goodInfo && goodInfo.goodsId,
            isPromote: values.isPromote,
            isPurchaseLimit: values.isPurchaseLimit,
            promoteStartDate:
              values.isPromote && values.promoteDate && values.promoteDate[0]
                ? values.promoteDate[0].format(DATE_TIME_FORMAT)
                : undefined,
            promoteEndDate:
              values.isPromote && values.promoteDate && values.promoteDate[1]
                ? values.promoteDate[1].format(DATE_TIME_FORMAT)
                : undefined,
            promotePrice:
              (values.isPromote && values.promotePrice) || undefined,
            purchaseLimitStartDate:
              values.isPurchaseLimit &&
              values.purchaseLimitDate &&
              values.purchaseLimitDate[0]
                ? values.purchaseLimitDate[0].format(DATE_TIME_FORMAT)
                : undefined,
            purchaseLimitEndDate:
              values.isPurchaseLimit &&
              values.purchaseLimitDate &&
              values.purchaseLimitDate[1]
                ? values.purchaseLimitDate[1].format(DATE_TIME_FORMAT)
                : undefined,
            purchaseLimitNum:
              (values.isPurchaseLimit && values.purchaseLimitNum) || undefined,

            isMember: values.isMember,
            memberPrice: values.memberPrice ? values.memberPrice : 0,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.goodsEdited'));
                handleCancelEditing();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    if (goodInfo) {
      if (isPromote !== goodInfo.promote.isPromote) {
        setIsPromote((prev) => !prev);
      }
      if (isPurchaseLimit !== goodInfo.purchaseLimit.isPurchaseLimit) {
        setIsPurchaseLimit((prev) => !prev);
      }
      if (parseFloat(goodInfo.memberPrice.toString()) !== 0) {
        setMemberPriceEmpty(false);
      }

      form.resetFields();
    }
    if (isSubscribed.current) {
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form
        form={form}
        initialValues={{
          ...goodInfo,
          isPromote: goodInfo.promote ? goodInfo.promote.isPromote : false,
          promotePrice:
            goodInfo.promote && goodInfo.promote.promotePrice !== 0
              ? goodInfo.promote.promotePrice
              : undefined,
          isPurchaseLimit: goodInfo.purchaseLimit
            ? goodInfo.purchaseLimit.isPurchaseLimit
            : false,
          purchaseLimitNum:
            goodInfo.purchaseLimit &&
            goodInfo.purchaseLimit.purchaseLimitNum !== 0
              ? goodInfo.purchaseLimit.purchaseLimitNum
              : undefined,
          promoteDate:
            goodInfo.promote &&
            goodInfo.promote.promoteStartDate &&
            goodInfo.promote.promoteEndDate
              ? [
                  moment(goodInfo.promote.promoteStartDate),
                  moment(goodInfo.promote.promoteEndDate),
                ]
              : undefined,
          purchaseLimitDate:
            goodInfo.purchaseLimit &&
            goodInfo.purchaseLimit.purchaseLimitStartDate &&
            goodInfo.purchaseLimit.purchaseLimitEndDate
              ? [
                  moment(goodInfo.purchaseLimit.purchaseLimitStartDate),
                  moment(goodInfo.purchaseLimit.purchaseLimitEndDate),
                ]
              : undefined,
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={16}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.add/editGood.specialInfo')}
            </Typography.Text>
            {goodInfo.mainGoods && (
              <Popover
                trigger={'click'}
                placement="right"
                content={<GoodsOverview goodInfo={goodInfo.mainGoods} isMainGoods = {true}/>}
              >
                <ProfileOutlined style={{ padding: 5, fontSize: '16px' }} />
              </Popover>
            )}
          </Col>
          <Col span={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editing ? (
                <Space>
                  <Button onClick={() => handleCancelEditing()}>
                    {t('goods.add/editGood.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                  >
                    {t('goods.add/editGood.ok')}
                  </Button>
                </Space>
              ) : (
                <Tooltip
                  title={t('goods.add/editGood.edit')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col
            span={isPromote ? 8 : 24}
            md={isPromote ? 12 : 24}
            lg={isPromote ? 4 : 24}
          >
            <Form.Item
              name="isPromote"
              label={t('goods.add/editGood.isPromote')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setIsPromote(checked);
                }}
              />
            </Form.Item>
          </Col>
          {isPromote && (
            <Col span={8} md={isPromote ? 12 : 24} lg={6}>
              <Form.Item
                name={editing ? 'promotePrice' : undefined}
                label={t('goods.add/editGood.promotePrice')}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: isPromote,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                {editing ? (
                  <InputNumber type="number" min={0} />
                ) : (
                  <>{goodInfo.promote ? goodInfo.promote.promotePrice : ''}</>
                )}
              </Form.Item>
            </Col>
          )}
          {isPromote && (
            <Col span={8} md={isPromote ? 12 : 24} lg={11}>
              <Form.Item
                name={editing ? 'promoteDate' : undefined}
                label={t('goods.add/editGood.promoteDate')}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                {editing ? (
                  <DatePicker.RangePicker
                    showTime
                    ranges={{
                      [t('goods.add/editGood.today')]: [
                        moment().startOf('day'),
                        moment().endOf('day'),
                      ],
                    }}
                    placeholder={[
                      t('goods.add/editGood.startDate'),
                      t('goods.add/editGood.endDate'),
                    ]}
                  />
                ) : (
                  <>
                    {goodInfo.promote &&
                      `${goodInfo.promote.promoteStartDate || ''} - ${
                        goodInfo.promote.promoteEndDate || ''
                      }`}
                  </>
                )}
              </Form.Item>
            </Col>
          )}
          <Col
            span={isPurchaseLimit ? 8 : 24}
            md={isPurchaseLimit ? 12 : 24}
            lg={isPurchaseLimit ? 4 : 24}
          >
            <Form.Item
              name="isPurchaseLimit"
              label={t('goods.add/editGood.isPurchaseLimit')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setIsPurchaseLimit(checked);
                }}
              />
            </Form.Item>
          </Col>
          {isPurchaseLimit && (
            <Col span={8} md={isPurchaseLimit ? 12 : 24} lg={6}>
              <Form.Item
                name={editing ? 'purchaseLimitNum' : undefined}
                label={t('goods.add/editGood.purchaseLimitNumber')}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                {editing ? (
                  <InputNumber type="number" min={0} />
                ) : (
                  <>
                    {goodInfo.purchaseLimit
                      ? goodInfo.purchaseLimit.purchaseLimitNum
                      : ''}
                  </>
                )}
              </Form.Item>
            </Col>
          )}
          {isPurchaseLimit && (
            <Col span={8} md={isPurchaseLimit ? 12 : 24} lg={11}>
              <Form.Item
                name={editing ? 'purchaseLimitDate' : undefined}
                label={t('goods.add/editGood.purchaseLimitDate')}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                {editing ? (
                  <DatePicker.RangePicker
                    showTime
                    ranges={{
                      [t('goods.add/editGood.today')]: [
                        moment().startOf('day'),
                        moment().endOf('day'),
                      ],
                    }}
                    placeholder={[
                      t('goods.add/editGood.startDate'),
                      t('goods.add/editGood.endDate'),
                    ]}
                  />
                ) : (
                  <>
                    {goodInfo.purchaseLimit &&
                      `${
                        goodInfo.purchaseLimit.purchaseLimitStartDate || ''
                      } - ${goodInfo.purchaseLimit.purchaseLimitEndDate || ''}`}
                  </>
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={12} md={8} lg={8}>
            <Form.Item
              name="isMember"
              label={t('goods.goodsListColumns.isMember')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Switch
                disabled={!editing}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={12} md={24} lg={16}>
            <Form.Item
              name={'memberPrice'}
              label={t('goods.goodsListColumns.memberPrice')}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Space>
                  {!memberPriceEmpty && (
                    <InputNumber
                      value={
                        goodInfo.memberPrice &&
                        parseFloat(goodInfo.memberPrice.toString()) !== 0
                          ? goodInfo.memberPrice
                          : undefined
                      }
                      type="number"
                      min={0}
                    />
                  )}

                  <Checkbox
                    defaultChecked={memberPriceEmpty}
                    onChange={(e) => {
                      console.log(`checked = ${e.target.checked}`);

                      if (!e.target.checked) {
                        setMemberPriceEmpty(false);
                      } else {
                        setMemberPriceEmpty(true);
                      }
                    }}
                  >
                    {t('goods.add/editGood.memberPriceEmpty')}
                  </Checkbox>
                </Space>
              ) : (
                <>
                  {goodInfo.memberPrice &&
                  parseFloat(goodInfo.memberPrice.toString()) !== 0
                    ? goodInfo.memberPrice
                    : 'N/A'}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.add/editGood.specialInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default GoodSpecialInfo;
